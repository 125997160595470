import React from "react"
import { graphql } from "gatsby";

import Layout from "../components/layout"

const MonthArchive = ({data, pageContext, location}) => {
  console.log("I am in month archive")
  const posts = data.allMarkdownRemark.edges;
  const year = pageContext.year
  const month = pageContext.month
  const filteredPosts = filterByYearMonth(posts, year, month);
  console.log(filteredPosts);
  return(
      <Layout location={location} data={filteredPosts}>
      </Layout>
  )
}

function filterByYearMonth(data, year, month)
{
  return data.map((post) => {
    console.log(year);
    console.log(month);
    var date = post.node.frontmatter.date
    var splstr = date.split(" ")
    if (year === splstr[2] && month === splstr[0])
    {
      return post;
    }
  }).filter(function (elim){
    return elim != null;
  })
}

export default MonthArchive

export const pagequery = graphql`
query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`